<template>
  <div id="app">
    <!-- file not attached yet -->
    <div>
      <div
        :class="['dropZone', dragging ? 'dropZone-over' : '']"
        @dragenter="dragging = true"
        @dragleave="dragging = false"
      >
        <div class="dropZone-info" @drag="onChange">
          <v-icon x-large class="dropZone-title"> mdi-cloud-upload</v-icon>
          <span class="ml-8 dropZone-title">Drop file or click to upload</span>
          <div class="mt-8 dropZone-upload-limit-info">
            <div>extension support: {{ EXTENSION_SUPPORT }}</div>
            <div>maximum file size: {{ MAX_FILE_SIZE / 1000 }} MB</div>
          </div>
        </div>
        <input type="file" multiple @change="onChange" />
      </div>
    </div>
    <!-- file attached -->
    <!-- <div v-else-if="file.name" class="dropZone-uploaded-info mt-3">
      <div>File Name: {{ file.name }}</div>
      <div>File Size: {{ (file.size / 1000 / 1000).toFixed(1) }}MB</div>
      <div class="mt-2">
        <v-btn @click="removeFile">
          <v-icon color="pink" small>mdi-close-circle</v-icon> Delete
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Fileupload",
  props: ['files'],
  data() {
    return {
      EXTENSION_SUPPORT: ["zip"],
      MAX_FILE_SIZE: 40*1000,
      dragging: false,
    };
  },
  methods: {
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) {
        this.dragging = false;
        return;
      }
      this.$debug('files', files)

      // Check total file size
      var totalSize = 0
      for(var t = 0; t < files.length; t++) {
        totalSize += files[t].size;
      }
      // Check File Size
      if (parseInt(totalSize / 1000) > this.MAX_FILE_SIZE) {
        alert(
          `Please upload total file size less than ${this.MAX_FILE_SIZE / 1000}MB. (Total : ${parseInt(totalSize / 1000 / 1000)}MB)`
        );
        this.dragging = false;
        return;
      }

      // Create File
      for(var i = 0; i < files.length; i++) {
        this.createFile( files[i] );
      }
    },
    createFile(file) {
      // Check Extensions
      this.$debug("file.type", file.name.split(".").pop(), this.EXTENSION_SUPPORT, this.EXTENSION_SUPPORT.includes(file.name.split(".").pop()));
      if (!this.EXTENSION_SUPPORT.includes(file.name.split(".").pop())) {
        alert(`Pease select ${this.EXTENSION_SUPPORT} file`);
        this.dragging = false;
        return;
      }

      // Check File Size
      // if (file.size / 1000 > this.MAX_FILE_SIZE) {
      //   alert(
      //     `Please check file size${file.size} no over ${this.MAX_FILE_SIZE} KB.`
      //   );
      //   this.dragging = false;
      //   return;
      // }

      // if (!this.files.filter(e => e === file).length) this.files.push(file);
      this.$debug(this.files);
      this.dragging = false;
    
      // let Parent update file object
      this.$emit("updateFile", file);
    },
    // removeFile(file) {
    //   this.files = this.files.filter(e => e !== file)
    //   this.$emit("deleteFile", this.files);
    // },
  },
  computed: {
    extension() {
      return this.file ? this.file.name.split(".").pop() : "";
    },
  },
};
</script>

<style scoped>
.dropZone {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone:hover {
  border: 2px solid #2e94c4;
}

.dropZone:hover .dropZone-title {
  color: #1975a0;
}

.dropZone-info {
  color: #a8a8a8;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translate(0, -50%);
  text-align: center;
}

.dropZone-title {
  font-size: 20px;
  color: #787878;
}

.dropZone input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropZone-upload-limit-info {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.dropZone-over {
  background: #5c5c5c;
  opacity: 0.8;
}

.dropZone-uploaded {
  width: 80%;
  height: 200px;
  position: relative;
  border: 2px dashed #eee;
}

.dropZone-uploaded-info div {
  line-height: 30px;
}

.removeFile {
  width: 200px;
}
</style>
