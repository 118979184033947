<template>
  <div>
    <template v-if="loadingStatus">
      <div class="uploading nodata" colspan="11" align="center">
        <v-progress-circular
          indeterminate
          color="amber"
        ></v-progress-circular>
      </div>
    </template>
    <Title :title="!sample ? 'Upload Scan' : `Update Scan ${sample.sample_date} : ${pond.pond_id}, ${pond.date}`">
    </Title>
    <v-card class="mx-auto mt-5 mb-5 px-3" tile>
      <v-list shaped class="pl-2">
        
        <v-list-item-group color="primary ">

          <!-- 1. New Input -->
          <template v-if="!sample">
            <div class="mt-5">
              <h4>Farm:</h4>
              <v-select
                v-model="currentfarm_id"
                :items="farm_list"
                item-value="id"
                label="select Farm"
                @change="changeFarm"
                dense
                solo
                :class="(currentfarm_id ? 'pondlist_completed' : '' )"
              >
                <template v-slot:item="{ item }">
                  <img :src="item.avatar" class="avatar"> {{item.name}} - {{item.active_pond_count}} Active
                </template>
                <template v-slot:selection="{ item }">
                  <img :src="item.avatar" class="avatar"> {{item.name}} - {{item.active_pond_count}} Active
                </template>
              </v-select>
            </div>
            <div v-if="!pond_list.length" class="ml-13">
              No pond in the farm.
            </div>
            <div v-else>
              <v-subheader>
                <h3>* File Name Format</h3>&nbsp;:&nbsp;
                <span class="red">[Device ID]</span>_
                <span class="blue">[Date]</span>_[time].zip&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ex) 
                <span class="red">0001</span>_<span class="blue">2021-12-02</span>_04-51-05.zip
              </v-subheader>
              <v-subheader class="ml-3">
                  (Date should be later than Pond Start Date)
              </v-subheader>
              <v-list-item key="status-1">
                <v-list-item-icon class="mt-6 left_name">
                  <v-icon class="mr-3">mdi-upload</v-icon>
                  Upload Sonar File:
                </v-list-item-icon>
                <v-list-item-content>
                  <fileupload :files="files" v-on:updateFile="updateFile" />
                  <p class="red--text mt-2" v-if="file_error">
                    Please upload your Sonar file.
                  </p>
                </v-list-item-content>
              </v-list-item>
              
              <v-list-item-group color="secondary" class="ma-2">
              <template v-for="(file, i) in files">
                <v-row :key="'filelist'+i" class="ma-2 pa-4" style="border: 18px solid #eee; border-radius: 10px;">
                  <v-row>
                    <v-col
                      class="d-flex align-center"
                      cols="1"
                    >
                    {{i+1}}
                    </v-col>
                    <v-col
                      class="d-flex"
                      cols="7"
                    >
                      <v-select
                        v-model="file.pond"
                        :items="pond_list"
                        item-value="id"
                        label="Automatically updated by 'File Name'"
                        solo
                        class="mt-5"
                      ></v-select>
                      <div class="activeonly">Active Only</div>
                    </v-col>
                    <v-col
                      class="d-flex flex-column justify-center"
                      cols="4"
                    >
                      <div>Sample Date : <strong>{{file.sample_date && file.sample_date}}</strong></div>
                      <div>Sample Time : <strong>{{file.sample_time && file.sample_time}}</strong></div>
                      <div>File Name: <strong style="font-size: 12px;">{{ file.file.name }}</strong></div>
                      <div>File Size: <strong>{{ (file.file.size / 1000 / 1000).toFixed(1) }}MB</strong></div>
                      <div class="mt-2">
                        <v-btn @click="deleteFile(file.file)">
                          <v-icon color="pink" small>mdi-close-circle</v-icon> Delete
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-list-item key="status-9" :class="isMobile ? 'block' : ''">
                      <v-list-item-icon class="mt-6 left_name">
                        <v-icon class="mr-3">mdi-order-bool-descending-variant</v-icon>
                        Note:
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-textarea
                            v-model="file.note"
                            solo
                            auto-grow
                            name="Note"
                            label=""
                          ></v-textarea>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>     
                  </v-row>
                </v-row>
            </template>
            </v-list-item-group>
            </div>
          </template>

          <!-- 2. Update sample -->
          <template v-if="sample">
            <!-- only minnowtech side can change Cound and Biomass -->
            <template v-if="isMinnowtech">
            <v-list-item key="status-3" class="calculate" :ripple="false">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-laptop</v-icon>
                Count:
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  label="Count"
                  v-model="count"
                  placeholder="Please input Count."
                  solo
                  class="mt-0"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>
            <v-list-item key="status-4" class="calculate" :ripple="false">
              <v-list-item-icon class="mt-6 left_name">
                <v-icon class="mr-3">mdi-laptop</v-icon>
                Biomass:
              </v-list-item-icon>
              <v-list-item-content>
                <v-text-field
                  label="Biomass"
                  v-model="biomass"
                  placeholder="Please input Biomass."
                  solo
                  class="mt-0"
                >
                </v-text-field>
              </v-list-item-content>
            </v-list-item>

            <v-list-item key="status-5" class="mt-3" :ripple="false">
            <v-list-item-content>
              <v-list-item-title>
                <div :class="'mx-8 ' + (isMobile ? '' : 'd-flex')">
                  <v-icon
                    v-if="file"
                    color="blue darken-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="mr-5"
                  >
                    mdi-download
                  </v-icon>
                  <v-checkbox
                    v-model="aerator"
                    :label="`Aerators On`"
                    class="flex-grow-1 mt-0"
                    :hide-details="true"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="feeder"
                    :label="`Feeder On`"
                    class="flex-grow-1 mt-0"
                    :hide-details="true"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="maintenance"
                    :label="`Maintenance`"
                    class="flex-grow-1 mt-0"
                    :hide-details="true"
                  ></v-checkbox>
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item key="status-9" :class="isMobile ? 'block' : ''" :ripple="false">
            <v-list-item-icon class="mt-6 left_name">
              <v-icon class="mr-3">mdi-order-bool-descending-variant</v-icon>
              Note:
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <v-textarea
                  v-model="note"
                  solo
                  auto-grow
                  name="Note"
                  label=""
                ></v-textarea>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>     
            </template>
            <!-- END : only minnowtech side can change Cound and Biomass -->
          </template>
          <!-- END : 2. Update sample -->
          

        </v-list-item-group>

        <div class="d-flex justify">
          <v-btn
            color="success"
            class="mt-5 mb-3"
            :width="(!sample ? '100%' : '40%')"
            @click="!sample ? addScan() : updateScan()"
          >
            {{!sample ? "Upload Scan" : "Update Scan"}}
          </v-btn>

          <v-btn v-if="sample" 
            color="warning mt-5 ml-3 mb-3 mx-auto"
            width="40%"
            @click="removeScan()"
          >
            {{"Delete Scan"}}
          </v-btn>
        </div>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";

import fileupload from "@/components/Fileupload";
import tokenAxios from "@/components/tokenAxios";
import Title from "@/components/Title";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { decimal } from "vuelidate/lib/validators";
// import { required, decimal } from "vuelidate/lib/validators";
import moment from 'moment'

export default {
  name: 'Scan',
  components: { fileupload, Title },
  computed: {
    ...mapState(["isMobile", "user_info", "isMinnowtech", "loadingStatus"]),
    sample: function () {
      return this.$route.params.sample  // Sample ID parameter from the list
    }
  },
  mixins: [validationMixin],
  validations: {
    assigned: {},
    // pond: { required },
    // week: { required },
    size: { decimal },
    temperature: { decimal },
    oxygen: { decimal },
    salinity: { decimal },
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    currentfarm_id: null, // current farm
    farm_list: [],  // farm list
    pond_list: [],  // pond list
    pond: null,
    sample_date: null,
    sample_time: null,
    count: null,
    biomass: null,
    aerator: false,
    feeder: false,
    maintenance: false,
    files: [],
    file_error: false,
    temperature: null,
    oxygen: null,
    salinity: null,
    note: '',
    size: null,
  }),
  created() {
    this.listFarms();
    this.$debug('$route.params', this.$route.params)
    // when Refresh browser send to pond list if user is farmer
    if( !this.sample && !this.isMinnowtech ) this.$router.push('/ponds')

    // Update sample/scan
    if(this.sample) {
      this.pond = this.sample.pond;
      this.sample_date = this.sample.sample_date;
      this.sample_time = this.sample.sample_time;
      this.count = this.sample.count;
      this.biomass = this.sample.biomass;
      this.aerator = this.sample.aerator;
      this.feeder = this.sample.feeder;
      this.maintenance = this.sample.maintenance;
      this.size = this.sample.size;
      this.file = this.sample.file;
      this.file_error = false;
      this.temperature = this.sample.temperature;
      this.oxygen = this.sample.oxygen;
      this.salinity = this.sample.salinity;
      this.note = this.sample.note;
    }
  },
  methods: {
    // Error Handling
    fieldErrors(field) {
      this.$debug('fieldErrors', field, this.$v, this.$v[field] )
      if (!this.$v[field].$dirty || !this.$v[field].$error) return [];
      else if (!this.$v[field].decimal) return ["Number is required."];
      else if (!this.$v[field].required) return ["This field is required."];
    },

    // List Farms
    async listFarms(page) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, this.user_info )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/farm/?no_page=1&format=json` + (page ? `&page=${this.page}` : "")
          );
          this.$debug("get farm lists", response);
          if (response.status === 200 && response.data.length) {
            this.farm_list = [];            
            const newlist = response.data.map(o=> {
              if( !o.avatar ) {
                o.avatar = "https://farm-avatar.s3.amazonaws.com/minnowtech.jpg";
              }
              return o;
            })

            this.farm_list.push(...newlist);
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // Change farm
    changeFarm(o) {
      this.$debug("changeFarm", o, this.farm)
      this.pond = null;
      this.listPonds(o, 1)

      // Clear uploading files
      this.files = [];
    },

    // Pond List to select
    async listPonds(farm) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER )
          this.pondlist_completed = false;
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/pond/?no_page=1&status=1&format=json` + (farm ? `&farm=${farm}` : "")
          );

          this.$debug("get project lists", response);
          if (response.status === 200 && response.data.length) {
            this.pond_list = response.data;
            this.pond_list.map(o => {
              this.$debug(`${o.pond_id} / ${o.date}`)
              o.text = `[${o.farm_name}] Pond ${o.pond_id} : ${o.date} (Pond Size: ${o.pond_size}, Shrimp Size: ${o.shrimp_size})`;
            });
            this.$debug( "this.pond_list", this.pond_list )
            this.pondlist_completed = true;
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // List scan
    listScan: async function (page) {
      try {
+        this.$store.commit("loadingStatus", 1);
        const response = await tokenAxios(this.user_info.access_token).get(
          this.API_SERVER + `/farm/info/?` + (page ? `&page=${this.page}` : "")
        );
        this.$debug("get project lists", response);
        if (response.status === 200 && response.data.results.length) {
          this.project_list = response.data.results;
          this.total = response.data.count;
        } else {
          this.$debug("error", response);
        }
      } catch (e) {
        this.$debug("error", e);
      } finally {
        this.$store.commit("loadingStatus", 0);
      }
    },

    // event after user delete file
    deleteFile: function (_file) {
      this.$debug("deleteParentFile", _file);

      // Delete new added pond
      this.files = this.files.filter(o => o.file !== _file);
    },
    
    // event after user selects file - file name verification
    updateFile: function (_file) {
      this.$debug("updateParentFile", _file);
      
      // add pond and pond list
      const input = _file.name.split('_')
      let input_device, input_date, input_time

      // file name verification : 1) Device SID
      try {
        input_device = input[0]
      } catch (e) {
        Swal.fire(
          `File name should start with Device ID (number)`,
          "",
          "error"
        );
        return;
      }
      // file name verification : 2) Date
      try {
        input_date = moment(input[1], 'YYYY-MM-DD')
      } catch (e) {
        Swal.fire(
          `File name is not valid for Date`,
          "",
          "error"
        );
        return;
      }
      // file name verification : 3) Time
      try {
        input_time = moment(input[2], 'HH:mm:ss')
      } catch (e) {
        Swal.fire(
          `File name is not valid for Time`,
          "",
          "error"
        );
        return;
      }
      this.$debug("Check date", input_date, input_time);
      this.$debug("this.pond_list", this.pond_list);

      let newfile = {
        file: _file,
        sample_date: input_date.format('YYYY-MM-DD'),
        sample_time: input_time.format('HH:mm:ss'),
        note: "",
      }

      // find pond cycle from descending pond list
      this.pond_list.find(o => {
        this.$debug( 'check...', o.pond_id, input_device, o.pond_id==input_device, input_date, moment(o.date) )
        o.device.find(d => {
          this.$debug('   device: ', d.sid, input_device, input_date.isAfter(moment(o.date)))
          if(d.sid==input_device && input_date >= moment(o.date)) {
            newfile.pond = o;
            return true;
          }
        })
      })

      // When no pond is matched...
      if( newfile.pond == undefined ) {
        this.$debug("no pond matched", this.farm_list, this.currentfarm_id)
        let farmname = this.farm_list.find(o => o.id === this.currentfarm_id).name;
        Swal.fire(
          `No active pond was found for [device: ${input_device}] in [Farm ${farmname}]`,
          "Please select another farm or select a pond manually!",
          "error"
        );
      }
      this.$debug("Check pond", newfile.pond, newfile, input_device, input_date);
      this.files.push( newfile );
      this.$debug("this.files", this.files)
      this.file_error = false;
    },

    // Add to the server
    async addScan() {
      // Check loadingStatus
      if( this.loadingStatus ) {
        Swal.fire(
          `Uploading ${this.files.length} file${(this.files.length) ? "s" : ""}...`,
          "",
          "error"
        );
        return;
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        // Focus error field
        for (let key in Object.keys(this.$v)) {
          const input = Object.keys(this.$v)[key];
          this.$debug("error", input, this.$v, key, "/", this.$v[input], this.$v[input].$error);

          if (input.includes("$")) return false;

          if (this.$v[input].$error) {
            this.$debug( input, this.$refs, this.$refs[input] )
            this.$refs[input].focus();
            if (input === 0) this.$vuetify.goTo(0);
            break;
          }
        }
        return;
      }

      try {
        this.$debug( "this.pond", this.pond )
        if(!this.files.length) {
          Swal.fire(`Please select scan file`,  "", "error");
          return;
        } 
        for(var i = 0; i < this.files.length; i++) {
          if(!this.files[i].pond) {
            Swal.fire(`Please select pond.`,  "", "error");
            return;
          }
        }

        this.$store.commit("loadingStatus", 1);
        var formData = new FormData();
        var msg = "";
        this.files.forEach(file => {
          formData.append("file", file.file);
          formData.append("pond", file.pond.id);
          formData.append("sample_date", file.sample_date);
          formData.append("sample_time", file.sample_time);
          formData.append("note", file.note);

          const _pond = this.pond_list.find(o => o.id === file.pond.id);
          if(msg) msg += ", ";
          msg += `[${file.sample_date} : Pond ${_pond.pond_id} / ${_pond.date}]`
        })        

        const result = await tokenAxios(this.user_info.access_token).post(
          process.env.VUE_APP_API_SERVER + `/farm/samples/`,
          formData
        );
        this.$debug( "result", result );
        Swal.fire(
          `${msg} is added`,
          "",
          "success"
        );

        // when success, set Submitted
        this.status = 0;

        // Set samples count
        this.files.map(o => {
          if(o.pond.id == this.files[0].pond.id) {
            this.files[0].pond.samples_count += 1
          }
        })
        
        // Set pond to first file's pond
        this.$store.commit("pond", this.files[0].pond);  
        console.log('move to ...', this.files, this.files[0].pond);
        this.$router.push('/samples'); // Go to the first file's pond
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // Update to the server
    async updateScan() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
        // focus error field
        for (let key in Object.keys(this.$v)) {
          const input = Object.keys(this.$v)[key];
          this.$debug("error", input, this.$v, key, "/", this.$v[input], this.$v[input].$error);

          if (input.includes("$")) return false;

          if (this.$v[input].$error) {
            this.$debug( input, this.$refs, this.$refs[input] )
            this.$refs[input].focus();
            if (input === 0) this.$vuetify.goTo(0);
            break;
          }
        }
        return;
      }

      try {
        this.$debug( "this.pond", this.pond )

        var formData = new FormData();
        if(!this.sample) formData.append("file", this.file);
        formData.append("pond", this.pond.id);
        formData.append("sample_date", this.sample_date);
        formData.append("sample_time", this.sample_time);
        formData.append("aerator", this.aerator ? 1 : 0);
        formData.append("feeder", this.feeder ? 1 : 0);
        formData.append("maintenance", this.maintenance ? 1 : 0);
        formData.append("note", this.note);

        if(this.count) formData.append("count", this.count);
        if(this.biomass) formData.append("biomass", this.biomass);

        this.$debug( "formData.....", formData, this.pond_list, this.pond )
        await tokenAxios(this.user_info.access_token).patch(
          process.env.VUE_APP_API_SERVER + `/farm/samples/${this.sample.id}/`,
          formData
        );
        Swal.fire(
          `Sample ${this.sample_date} @ Pond ${this.pond.pond_id} / ${this.pond.date} is updated`,
          "",
          "success"
        );

        // set Submitted
        this.status = 0;
        // this.listPonds();
        this.$store.commit("pond", this.pond);
        this.$router.push('/samples')
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Initialize
    clear() {
      this.$v.$reset();
      this.name = "";
      this.email = "";
      this.select = null;
      this.checkbox = false;
    },

    // Remove scan
    async removeScan() {
      const result = await Swal.fire({
        title: this.sample.sample_date,
        text: "Are you sure you want to remove this Sample?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
      });
      if (!result.isConfirmed) return;

      try {
        const response = await tokenAxios(this.user_info.access_token).delete(
          this.API_SERVER + `/farm/samples/${this.sample.id}/`
        );
        if (response.status === 200) {
          this.$debug("result", response);
          Swal.fire("Deleted!", `Sample ${this.sample.sample_date} has been deleted.`, "success");
          this.$router.push('/samples')
        }
      } catch (error) {
        this.$debug("error", error.response);
      }
    },
  },
}
</script>

<style scoped>
.uploading {
  position: fixed;
  top: 200px;
  left: 50vw;
  margin: auto;
  z-index: 999;
}
.avatar {
  width: 30px;
  margin-right: 15px;
  border-radius: 10px;
}
</style>
<style>
.v-list-item {
  cursor: auto;
}
.subtitle {
  height:80px;
  margin: 20px 0;
}
.desc_container {
  background: url('/bg3.jpg');
  background-size: cover;
  opacity: 1;
  min-height: 200px;
  border-radius: 10px;
}
.v-list .left_name {
  min-width: 180px;
  margin: auto;
}
.blue, .red {
  color: #fff;
  border-radius: 5px;
}
.calculate {
  background-color: #e8eff5;
}
.activeonly {
  font-size: 9px;
  color: red;
}
.pondlist_completed .v-input__slot {
  background-color: #edfeee!important;
}
</style>
